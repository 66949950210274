<template>
  <div class="container mb-5">
    <b-row class="mb-4 pt-4 d-flex justify-content-between text-start">
      <b-col><curva-title :title="$t('mainNav.images')"></curva-title></b-col>
    </b-row>
  <div class="photo-container">
    <!--    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="sport">
        </div>
        <div class="text">Sport</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-3">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="people">
        </div>
        <div class="text">People</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="sport">
        </div>
        <div class="text">Sport</div>
      </div>
    </div>

    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>

    <div class="gallery-container iq-border-radius-10 w-2 h-3">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-3">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>
    <div class="gallery-container iq-border-radius-10 w-2 h-2">
      <div class="gallery-item">
        <div class="image">
          <img src="https://source.unsplash.com/1600x900/?basketball" alt="nature">
        </div>
        <div class="text">Nature</div>
      </div>
    </div>-->
    <div v-for="(image,key) in photosList" :key="key" :class="['gallery-container iq-border-radius-10 w-2',
    [1,6,7].indexOf(key) > -1 ? 'h-3' : 'h-2']">
      <div class="gallery-item">
        <div class="image">
          <img :src="image.image" :alt="image.name">
        </div>
        <div class="text">{{ image.name }}</div>
      </div>
    </div>
  </div>
    <div class="d-flex justify-content-center align-content-center mt-5">
      <b-pagination
          v-if="rows > perPage"
          class="curva-pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @input="getMedia"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import mediaServices from '../services/multimedia.services'
export default {
  data () {
    return {
      perPage: 8,
      currentPage: 1,
      rows: 3,
      photosList: []
    }
  },
  methods: {
    getMedia () {
      mediaServices.getMedia('image', this.currentPage).then(res => {
        this.photosList = res.data.data.data
        this.perPage = res.data.per_page
        this.currentPage = res.data.current_page
        this.rows = res.data.total
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getMedia()
  }
}
</script>
<style>
.photo-container{
  display:grid;
  grid-template-columns: repeat(6,1fr);
  grid-auto-rows:100px 300px;
  grid-gap:20px;
  grid-auto-flow: dense;
}
.gallery-item{
  width:100%;
  height:100%;
  position:relative;
}
.gallery-item .image{
  width:100%;
  height:100%;
  border-radius: 15px;
  overflow:hidden;
}
.gallery-item .image img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position:50% 50%;
  cursor:pointer;
  transition:.5s ease-in-out;
}
.gallery-item:hover .image img{
  transform:scale(1.5);
}
.gallery-item .text{
  opacity:0;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  color:#fff;
  font-size:25px;
  pointer-events:none;
  z-index:4;
  transition: .3s ease-in-out;
  -webkit-backdrop-filter: blur(5px) saturate(1.8);
  backdrop-filter: blur(5px) saturate(1.8);
}
.gallery-item:hover .text{
  opacity:1;
  animation: move-down .3s linear;
  padding:1em;
  width:100%;
}
.w-1{
  grid-column: span 1;
}
.w-2{
  grid-column: span 2;
}
.w-3{
  grid-column: span 3;
}
.w-4{
  grid-column: span 4;
}
.w-5{
  grid-column: span 5;
}
.w-6{
  grid-column: span 6;
}
.h-1{
  grid-row: span 1;
}
.h-2{
  grid-row: span 2;
}
.h-3{
  grid-row: span 3;
}
.h-4{
  grid-row: span 4;
}
.h-5{
  grid-row: span 5;
}
.h-6{
  grid-row: span 6;
}
@media screen and (max-width:500px){
  .container{
    grid-template-columns: repeat(1,1fr);
  }
  .w-1,.w-2,.w-3,.w-4,.w-5,.w-6{
    grid-column:span 1;
  }
}
@keyframes move-down{
  0%{
    top:10%;
  }
  50%{
    top:35%;
  }
  100%{
    top:50%;
  }
}
</style>
